import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var emoji = function emoji() {
  return import("../common/emoji");
};

var proButton = function proButton() {
  return import("../common/proButton");
};

var uploadPicture = function uploadPicture() {
  return import("../common/uploadPicture");
};

export default {
  components: {
    emoji: emoji,
    proButton: proButton,
    uploadPicture: uploadPicture
  },
  props: {
    disableGraffiti: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      commentContent: "",
      showEmoji: false,
      showPicture: false,
      picture: {
        name: this.$store.state.currentUser.username,
        url: ""
      }
    };
  },
  methods: {
    openPicture: function openPicture() {
      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.showPicture = true;
    },
    addPicture: function addPicture(res) {
      this.picture.url = res;
      this.savePicture();
    },
    savePicture: function savePicture() {
      var img = "<" + this.picture.name + "," + this.picture.url + ">";
      this.commentContent += img;
      this.picture.url = "";
      this.showPicture = false;
    },
    addEmoji: function addEmoji(key) {
      this.commentContent += key;
    },
    showGraffiti: function showGraffiti() {
      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.commentContent = "";
      this.$emit("showGraffiti");
    },
    submitComment: function submitComment() {
      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      if (this.commentContent.trim() === "") {
        this.$message({
          message: "你还没写呢~",
          type: "warning"
        });
        return;
      }

      this.$emit("submitComment", this.commentContent.trim());
      this.commentContent = "";
    }
  }
};